@font-face {
  font-family: 'Road Rage';
  src: local('Road Rage'), url(../fonts/Road_Rage.otf) format('opentype');
}

@font-face {
  font-family: 'Commando';
  src: local('Commando'), url(../fonts/commando.ttf) format('truetype');
}


.game__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #141622CC;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 300ms ease;
  transition: background-color 1000ms ease;
  z-index: 1000;
  }

.game__subcontainer {
  margin: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.game__logo {
  max-width: 100%;
  max-height: 100%;
  width: 1024px;
  margin-top: 2rem;
}

.game__logo-small {
  height: 256px;
  width: 512px;
}

.game__menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.game__menu-options {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.game__score-gameover {
  color: #fe2079;
  font-family: 'Commando', Inconsolata, monospace;
  font-size: 12rem;
  text-shadow: 0 0 10px #fe2079;
  margin: 0;
  margin-bottom: 2rem;
}

.game__score-title {
  color: #fe2079;
  font-family: 'Road Rage', Inconsolata, monospace;
  font-weight: 500;
  font-size: 4rem;
  text-align: center;
  text-shadow: 0 0 40px #fe2079;
  margin: 0;
  margin-bottom: 1rem;
}

.game__score {
  color: #eee;
  font-family: 'Commando', Inconsolata, monospace;
  font-size: 10rem;
  font-weight: 500;
  text-align: center;
  text-shadow: 0 0 20px #fe2079;
  margin: 0;
}

.game__scorecontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-shadow: 0 0 20px #fe2079;
  margin-bottom: 2rem;
}

.game__score-highscore {
  font-size: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
  color: #eee;
  font-family: 'Commando', Inconsolata, monospace;
}

.game__score-number {
  color: #fe2079;
  margin-right: 1rem;
}

.game__score-left {
  margin-right: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game__score-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game__menu-button {
  background: none;
  border-radius: 20px;
  border: 0;
  font-family: 'Road Rage', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 6rem;
  color: #fe2079;
  text-shadow: 0 0 40px #fe2079;
}

.game__menu-button-music {
  font-size: 3.5rem;
}

.game__menu-warning {
  margin-top: 8rem;
  font-family: 'Commando', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2rem;
  color: #fe2079;
  text-shadow: 0 0 20px #fe2079;
}

.game__menu-musicinfo {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: 'Commando', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  color: #fe2079;
  text-shadow: 0 0 20px #fe2079;
}

.game__menu-controls {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-family: 'Road Rage', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2rem;
  color: #fe2079;
  text-shadow: 0 0 20px #fe2079;
}

.game__menu-controls > p {
  margin: 0;
}

.game__menu-button:hover {
  color: #FFF;
  cursor: pointer;
}


@media screen and (max-width: 992px), (max-height: 1080px) {
.game__logo {
  height: 360px;
  width: 720px;
}

.game__logo-small {
  height: 175px;
  width: 350px;
}

.game__menu-button {
  font-size: 4rem;
  text-shadow: 0 0 15px #fe2079;
}

.game__menu-button-music {
  font-size: 2.5rem;
}

.game__menu-warning {
  text-shadow: 0 0 15px #fe2079;
}

.game__score-gameover {
  font-size: 8rem;
  text-shadow: 0 0 10px #fe2079;
}

.game__score-title {
  font-size: 3rem;
}

.game__score {
  font-size: 6rem;
}

.game__menu-warning {
  font-size: 1.5rem;
  margin-top: 0;
}

.game__menu-musicinfo {
  font-size: 1.2rem;
}
.game__menu-controls {
  font-size: 1.5rem
}

.game__subcontainer {
  margin-bottom: 1rem;
}
}

@media screen and (max-width: 600px), (max-height: 700px) {
  .game__menu-musicinfo {
    font-size: 1rem;
  }

  .game__subcontainer {
    margin: 0;
  }
  .game__menu-controls {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  font-size: 1.2rem
}
  .game__scorecontainer {
  flex-direction: column;
  justify-content: center;
  }

.game__score-left {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .game__score-highscore {
    font-size: 2.5rem;
  }

.game__logo {
  height: 128px;
  width: 256px;
}

.game__logo-small {
  height: 128px;
  width: 256px;
}

.game__menu-button {
  font-size: 3rem;
  text-shadow: 0 0 15px #fe2079;
}

.game__menu-button-music {
  font-size: 2rem;
}

.game__menu-warning {
  font-size: 1rem;
  text-shadow: 0 0 15px #fe2079;
}

.game__score-gameover {
  font-size: 4rem;
  text-shadow: 0 0 10px #fe2079;
  margin-bottom: 1rem;
}

.game__score-title {
  font-size: 2rem;
  text-shadow: 0 0 5px #fe2079;
}

.game__score {
  font-size: 4rem;
  text-shadow: 0 0 10px #fe2079;
}
}
