.hud {
  font-family: 'Commando', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-shadow: 0 0 40px #fe2079;
  color: #eee;
  font-size: 8rem;
  font-weight: 700;
}

@keyframes pulse {
  0% {
    color: #eee;
    opacity: 1;
  }
  50% {
    color: #fe2079;
    opacity: 0.7;
  }
  100% {
    color: #eee;
    opacity: 1;
  }
}

@keyframes wiggle {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(0%);
  }
}

.center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center__speedup {
  color: #eee;
  animation: pulse 0.5s ease-in-out infinite, wiggle 0.3s ease-in infinite;
}

.bottomLeft {
  position: absolute;
  bottom: 2vh;
  left: 4vh;
  transform: skew(0deg, -10deg);
  width: 200px;
  margin: 0;
}

.score {
  opacity: 1;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.score__number {
  font-size: 8rem;
  font-weight: 500;
  margin: 0;
}

.score__title {
  color: #fe2079;
  font-size: 3rem;
  font-weight: 500;
  margin: 0;
  margin-top: 0.5rem;
  font-family: 'Commando', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.score__withcontrols {
  margin-bottom: 10rem;
}

.controls {
  position: absolute;
  width: 100vw;
  display: flex;
  bottom: 2vh;
  justify-content: space-between;
  padding-left: 2vh;
  padding-right: 2vh;
  z-index: 1001;
}

.control {
  opacity: 0.3;
  padding-left: 2rem;
  padding-right: 2rem;
  color: #fe2079;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6rem;
  border-radius: 20px;
  background-color: transparent;
  cursor: default;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  box-shadow: 0 0 40px #fe2079;
  text-shadow: 0 0 40px #fe2079;
}

.control-active {
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .bottomLeft {
    left: 2vh;
  }
  
  .score__number {
    font-size: 4rem;
  }
  .score__title {
    font-size: 2rem;
  }
  .hud {
    font-size: 5rem; 
  }
}

@media screen and (max-width: 600px) {
  .center {
    font-size: 3rem;
  }

  .bottomLeft {
    left: 2vh;
  }
  .score__withcontrols {
   margin-bottom: 7rem;
  }
  .score__number {
    font-size: 2rem;
  }
  .score__title {
    font-size: 1rem;
  }
  .hud {
    text-shadow: 0 0 15px #fe2079;
    font-size: 5rem; 
  }

  .control {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 4rem;
    box-shadow: 0 0 10px #fe2079;
  }
}